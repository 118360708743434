<template>
  <div :data-shoppertraining="shopper_training">
    <h3 class="mb-4">{{ $t("SHOPPER_TRAINING_HEADLINE") }}</h3>

    <div class="mb-3">
      <p class="sublines">
        Do you want to approve the shopper profiles before the conduction of the
        shop (Agency delivers profiles in bundles)
      </p>
      <Switch
        :initValue="shopper_training.approval"
        :disabled="activeWaveReadonly"
        v-on:changed="
          (checked) => setShopperTrainingAndApproval('approval', checked)
        "
      />
    </div>

    <div class="mb-3">
      <p class="sublines">
        {{ $t("SHOPPER_TRAINING_INTERESTED") }}
      </p>
      <label>
        <select
          class="form-select"
          :disabled="activeWaveReadonly"
          @change="saveInterestedTrainingType($event)"
        >
          <option
            v-for="type in interestedTrainingTypes"
            :key="type.id"
            :value="type.value"
            :data-id="type.id"
            :selected="shopper_training.interesstedTrainingType.id === type.id"
          >
            {{ type.title }}
          </option>
        </select>
      </label>
    </div>

    <div>
      <label class="pb-2" for="comment-textarea">{{
        $t("PLEASE_COMMENT")
      }}</label>
      <textarea
        class="w-100 form-control"
        id="comment-textarea"
        v-model="shopper_training.comment"
        :readonly="activeWaveReadonly"
        @change="
          setShopperTrainingAndApproval('comment', shopper_training.comment)
        "
      ></textarea>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
export default {
  name: "ShopperTraining",
  components: { Switch },
  methods: {
    saveInterestedTrainingType($event) {
      this.setShopperTrainingAndApproval("interesstedTrainingType", {
        id: Number($event.target.selectedOptions[0].dataset.id),
        value: $event.target.value,
        title: $event.target.selectedOptions[0].innerText,
      });
    },
    setShopperTrainingAndApproval(key, value) {
      let shopper_training = JSON.parse(JSON.stringify(this.shopper_training));
      shopper_training[key] = value;
      this.$store.dispatch(
        "waves/setShopperTrainingAndApproval",
        shopper_training
      );
    },
  },
  computed: {
    shopper_training() {
      return this.$store.getters["waves/getShopperTrainingAndApproval"];
    },
    interestedTrainingTypes() {
      return this.$store.getters["waves/getInterestedTrainingTypes"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  width: auto;
  min-width: 200px;
}
</style>
